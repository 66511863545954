import React from 'react';
import { Link } from "gatsby";

const AboutIntro = () => {
    return (
        <section className="about-video-area section-gap">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 about-video-left">
                        {/* <h6 className="text-uppercase">Our Mission</h6> */}
                        <h1 style={{ textTransform: "uppercase" }}>
                            Our  <br />
                            Mission
                        </h1>
                        <p>
                            <span>We are here to listen from you deliver exellence</span>
                        </p>
                        <p>
                            Our aim is to bring to our clients a steady set of resources that would help them visualize, create and maintain beautiful homes. Our emphasis is on visuals that motivate you. We cover architectural innovations, cool homes, ideas for specific rooms, new design trends, products and occasionally decor tips. We hope to become your one stop source for home design inspiration!
                        </p>
                        <Link className="primary-btn mt-30" to="/contact/">Get Started Now</Link>
                    </div>
                    <div
                        className="col-lg-6 about-video-right justify-content-center align-items-center d-flex relative"
                    >
                        <div className="overlay overlay-bg"></div>
                        {/* <a
                            className="play-btn"
                            href="https://www.youtube.com/watch?v=ARA0AxrnHdM"
                        ><img className="img-fluid mx-auto" src="img/play-btn.png" alt=""
                            /></a> */}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutIntro;