import React from 'react';
import HalfBanner from "../components/halfBanner";
import AboutIntro from "../components/aboutIntro";
import Layout from '../components/layout';
import Seo from "../components/seo";
import Features from "../components/features";
import Testimonials from "../components/testimonials";
import RequestQuote from "../components/quote";

const About = () => {
    return (
        <Layout>
            <Seo title="About Us" />
            <HalfBanner
                title="About Us"
            />
            <AboutIntro />
            <Features />
            {/* <Testimonials /> */}
            <RequestQuote />
        </Layout>

    );
};

export default About;